<template>
  <div v-if="independent && independent.status !== null && independent.status !== constants.INDEPENDENT_STATUS.DRAFT && missions"
       class="flex-container non-scrollable">
    <div v-if="getIsBrowserApp && hasAccess($route.name, true)" class="flex justify-content-end p-3 gap-3">
      <Button v-if="isSavable" label="Enregistrer" class="p-button-rounded" size="small" @click="save" />
      <Button v-if="isSavable || isSavableAdmin" label="Valider la facturation" class="p-button-rounded" size="small"
              @click="validate"/>
    </div>
    <div class="scrollable">
      <cra ref="craFacturation" :cra-type="constants.CRA_TYPES.INVOICE" class="pr-3"
           :show-other-months="editable" :editable="editable"
           @numberCra="numberCra = $event" @changeCurrentDate="init"/>
      <declare-facture-detail v-if="editable && initialized" :editable="editable" :is-savable="isSavable || isSavableAdmin" class="pr-3"/>
      <div class="spacer h-10rem"/>
    </div>
  </div>
  <div v-else>
    Votre contrat de travail doit être signé pour déclarer vos premières facturations
  </div>
  <div v-if="!getIsBrowserApp" class="absolute bottom-0 left-0 w-full z-2">
    <Calendar
        v-model="currentMonth"
        class="special-mobile-calendar p-button-sm w-full justify-content-center"
        input-class="p-button"
        input-style="border-radius: 0 !important;"
        view="month"
        date-format="MM yy"
        :manual-input="false"
        touch-u-i
        @update:modelValue="changeMonth"
    />
    <div v-if="hasAccess($route.name, true)" class="h-3rem">
      <Button
          class="p-button-sm w-6 justify-content-center h-3rem"
          style="border-radius: 0 0 0 10px; box-shadow: 0 0 2px rgb(0 0 0 / 25%)"
          :disabled="!isSavable"
          @click="save"
      >
        Enregistrer
      </Button>
      <Button
          class="p-button-sm w-6 justify-content-center h-3rem"
          style="border-radius: 0 0 10px 0; box-shadow: 0 0 2px rgb(0 0 0 / 25%)"
          :disabled="!isSavable"
          @click="validate"
      >
        Valider la facturation
      </Button>
    </div>
  </div>
  <DialogConfirm
      :title="'ÊTES-VOUS SÛR ?'"
      :text-question="'Êtes-vous sûr de vouloir valider les informations rentrées précédemment ?'"
      :text-info="me.role.name === constants.ROLES.ADMIN ? '(Les services nécessaires seront créés)' : '(Une fois validées les informations saisies ne pourront plus être modifiées)'"
      :refuse-action="refuseDialogValidation"
      :validate-action="confirmDialogValidation"
      :is-active="confirmValidationDialog"
  />
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import Cra from '@/components/Cra'
import DeclareFactureDetail from '@/components/Facturation/DeclareFactureDetail'
import { disablePComponentsOfCurrentPage, hasAccess} from "@/utils/Misc";
import Alert from "@/utils/Alert";
import DialogConfirm from "@/components/DialogConfirm.vue";
import isBrowserAppMixin from "@/mixins/isBrowserAppMixin";

export default {
  name: 'DeclareFacture',
  mixins: [isBrowserAppMixin],
  components: {DialogConfirm, Cra, DeclareFactureDetail },
  props: {
    independentIdForced: {
      type: String,
      default: null,
      required: false
    },
    monthForced: {
      type: Number,
      default: null,
      required: false
    },
    yearForced: {
      type: Number,
      default: null,
      required: false
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      hasAccess: hasAccess,
      confirmValidationDialog: false,
      currentMonth: null,
      initialized: false,
      numberCra: 0
    }
  },
  computed: {
    ...mapState({
      constants: state => state.constants,
      declarationsFacturation: state => state.declarationFacturation.declarationsFacturation,
      missionPricingByTimesheet: state => state.declarationFacturation.missionPricingByTimesheet
    }),
    ...mapGetters({
      me: 'auth/getMe',
      independent: 'independent/getIndependent',
      missions: 'mission/getMissions',
      craDate: 'cra/getDate',
      // servicesForfait: 'service/getServicesForfait',
    }),
    isSavable() {
      if (!this.editable || this.numberCra === 0) return false
      // todo: should prevent the independent to validate
      return this.declarationsFacturation.some(df => df.status === this.constants.FACTURATION_STATUS.DRAFT)
      // const isValidate = this.declarationsFacturation.filter((lf) => { return lf.status !== this.constants.FACTURATION_STATUS.DRAFT })
      // return (isValidate && isValidate.length === 0)
    },
    isSavableAdmin () {
      if (!this.editable || this.numberCra === 0) return false
      return this.me.role.name === this.constants.ROLES.ADMIN && this.declarationsFacturation.some(df => df.status !== this.constants.FACTURATION_STATUS.VALIDATED)
    }
  },
  watch: {
    '$route.query.idIndependent': {
      async handler () {
        if (this.$route.query.idIndependent) {
          await this.init({})
        }
      },
      deep: true
    }
  },
  async mounted() {
    await this.init({})
  },
  methods: {
    async init({month = null, year = null}) {
      this.$store.state.misc.loading = true
      this.initialized = false
      let currentMonth = month
      let currentYear = year
      let date
      if (this.me.role.name === this.constants.ROLES.ADMIN) {
        await this.$store.dispatch('independent/getOneIndependent', this.independentIdForced ? this.independentIdForced : this.$route.query.idIndependent)
      } else {
        await this.$store.dispatch('independent/getOneIndependentByUser', this.me.id)
      }

      if (this.independent.status === null || this.independent.status === this.constants.INDEPENDENT_STATUS.DRAFT) {
        this.$store.state.misc.loading = false
        return
      }

      if (month === null || year === null) {
        date = new Date()
        currentMonth = this.monthForced ? this.monthForced : (this.$route.query.month ? parseInt(this.$route.query.month) : date.getMonth())
        currentYear = this.yearForced ? this.yearForced : (this.$route.query.year ? parseInt(this.$route.query.year) : date.getFullYear())

        // if (!this.monthForced || !this.yearForced) {
        //   const lastFacturationsNotValidate = await this.$store.dispatch('declarationFacturation/getLastInvalidateFacturation', { independentId: this.independent.id, month: currentMonth, year: currentYear})
        //   if (lastFacturationsNotValidate?.length > 0) {
        //     currentMonth = lastFacturationsNotValidate[0]?.month
        //     currentYear = lastFacturationsNotValidate[0]?.year
        //   }
        // }
      }

      let data = {
        month: currentMonth,
        year: currentYear
      }
      await this.$store.dispatch('cra/updateDate', data)
      this.currentMonth = new Date(Date.UTC(currentYear, currentMonth, 1))
      await this.$store.dispatch('mission/getMissionsOfCurrentMonthByIndependent', { independentId: this.independent.id, month: data.month, year: data.year })
      // await this.prepareServiceForfait()
      await this.$refs.craFacturation.changeCra()
      await this.$nextTick(() => {
        if (!hasAccess(this.$route.name, true)) disablePComponentsOfCurrentPage(document)
      })
      this.initialized = true
      this.$store.state.misc.loading = false
    },
    changeMonth (value) {
      this.init({ month: value.getMonth(), year: value.getFullYear()})
    },
    formatFacturations() {
      this.$store.dispatch('declarationFacturation/getAllDeclarationFacturationByIndep', {
        idIndep: this.independent.id,
        month: this.craDate.month,
        year: this.craDate.year
      }).then(() => {
        this.$store.state.misc.loading = false
      })
    },
    async save() {
      this.$store.state.misc.loading = true
      // todo: must do it in one request with the payload of each ?
      this.declarationsFacturation.forEach((df) => {
        this.missions.forEach((m) => {
          if (df.mission === m.id) {
            df.timesheet = m.currentCra.timesheet
            if (df.id) {
              this.$store.dispatch('declarationFacturation/updateDeclarationFacturation', df).then(() => {
                this.formatFacturations()
                Alert.successMessage(this, 'saveDeclareFacturation')
              })
            }
          }
        })
      })
    },
    validate() {
      this.confirmValidationDialog = true
    },
    refuseDialogValidation() {
      this.confirmValidationDialog = false
    },
    async confirmDialogValidation() {
      // todo: should make the save in this before the rest ?
      this.confirmValidationDialog = false
      this.$store.state.misc.loading = true
      let newServices = []
      let newService = null
      // todo: 1 service par tj par timesheet (sur site / teleworking) -- done
      for (const invoiceDeclaration of this.declarationsFacturation) {
        if (invoiceDeclaration.id && invoiceDeclaration.status !== this.constants.FACTURATION_STATUS.VALIDATED) {
          this.missions.forEach((mission, index) => {
            if (invoiceDeclaration.mission === mission.id) {
              invoiceDeclaration.timesheet = mission.currentCra.timesheet
              // todo: the computation is done before, no need to check whether it's onSite or onRemote ?
              this.missionPricingByTimesheet[index].forEach((timesheet) => {
                timesheet.forEach((timesheetNegotiatedRate) => {
                  newService = {
                    mission: mission.id,
                    independent: this.independent.id,
                    label: 'Prestation ' + (timesheetNegotiatedRate.label?.toLowerCase()?? 'forfait') + ' ' + new Date(Date.UTC(invoiceDeclaration.year, invoiceDeclaration.month, 1)).toLocaleDateString('fr-FR', {year: 'numeric', month: 'long'}),
                    status: 'toInvoice', // todo: define the status in the constants
                    month: invoiceDeclaration.month,
                    year: invoiceDeclaration.year,
                    price: timesheetNegotiatedRate.totalCostWithoutTaxes / 100, // todo: REALLY NEED TO REFACTOR EVERYTHING IN THE BACK - TOO MUCH MICMAC (/100 then *100 then /100 then *100)
                    montantHT: timesheetNegotiatedRate.unitCost / 100, // todo: REALLY NEED TO REFACTOR EVERYTHING IN THE BACK - TOO MUCH MICMAC
                    quantity: timesheetNegotiatedRate.numberOfOccurrences,
                    commentaire: mission.commentaire, // duplication de commentaire ?
                    declarationFacturation: invoiceDeclaration.id,
                    source: { create: [ { Service_id: '+', collection: 'DeclarationFacturation', item: { 'id': invoiceDeclaration.id } } ] },
                  }
                  if (newService.price > 0) newServices.push(newService)
                  newService = null
                })
              })
            }
          })
          invoiceDeclaration.status = this.constants.FACTURATION_STATUS.VALIDATED
          invoiceDeclaration.services = [...invoiceDeclaration.services.map(s => ({ ...s, hide: false })), ...newServices]
          await this.$store.dispatch('declarationFacturation/updateDeclarationFacturation', invoiceDeclaration)
          this.$store.state.misc.loading = false
          Alert.successMessage(this, 'declareFacturationValidate')
        } else {
          this.$store.state.misc.loading = false
        }
        // reset the services
        newServices = []
      }
    }
  }
}
</script>
<style>
.special-mobile-calendar .p-inputtext:enabled:focus {
  box-shadow: none;
}
.special-mobile-calendar .p-button:focus {
  box-shadow: none;
}
</style>
