<template>
  <div class="p-5">
    <Card class="p-5">
      <template #content>
        <div class="grid formgrid p-fluid">
          <div v-if="me.role.name === constants.ROLES.ADMIN" class="field mb-4 col-12">
            <label for="service-independent">Porté</label>
            <AutoComplete id="service-independent" v-model="independent" :suggestions="filteredIndependent"
                          :option-label="(val) => val.first_name + ' ' + val.last_name"
                          dropdown :disabled="!!service?.independent"
                          @clear="clearIndependent"
                          @complete="searchIndependent($event)"
                          @dropdown-click="searchIndependent($event, true)"
                          @item-select="(event) => selectIndependent(event.value)"/>
          </div>
          <div v-if="independent && me.role.name === constants.ROLES.ADMIN" class="field mb-4 col-12">
            <label for="service-mission">Mission : </label>
            <Dropdown id="service-mission" v-model="mission" :options="missionOptions"
                      :option-label="(val) => val.clientContract.name"
                      :disabled="!!service?.mission"/>
          </div>
          <div v-if="me.role.name === constants.ROLES.ADMIN" class="field mb-4 col-12">
            <label>Mois de facturation</label>
            <Calendar v-if="me.role.name === constants.ROLES.ADMIN" :showIcon="true" v-model="moisFacturation" view="month" date-format="mm/yy"></Calendar>
          </div>
          <div v-if="independent" class="field mb-4 col-12">
            <label>{{ me.role.name === constants.ROLES.INDEPENDENT ? 'Label' : 'Service' }}</label>
            <InputText type="text" v-model="label" :disabled="me.role.name === constants.ROLES.INDEPENDENT" highlightOnFocus/>
          </div>
          <div v-if="independent && me.role.name === constants.ROLES.ADMIN" class="field mb-4 col-12">
            <label>Quantité</label>
            <InputNumber v-model="quantity" :min="0" :minFractionDigits="2" :maxFractionDigits="2" highlightOnFocus/>
          </div>
          <div v-if="independent && me.role.name === constants.ROLES.ADMIN" class="field mb-4 col-12">
            <label>Prix unit. HT</label>
            <InputNumber v-model="montantHT" locale="fr-FR" mode="currency" currency="EUR" highlightOnFocus/>
          </div>
          <div v-if="independent && me.role.name === constants.ROLES.ADMIN" class="field mb-4 col-12">
            <label>Montant</label>
            <InputNumber v-model="price" :min="0" locale="fr-FR" mode="currency" currency="EUR" highlightOnFocus/>
          </div>
          <div v-if="independent" class="field mb-4 col-12">
            <label>Commentaire</label>
            <Textarea v-model="commentaire" :rows="3"/>
          </div>
        </div>
      </template>
    </Card>
    <div class="flex justify-content-evenly mt-5">
      <Button v-if="mission && me.role.name === constants.ROLES.ADMIN" @click="openFicheMission"
              type="button" class="p-button-secondary">
        Voir la fiche mission
      </Button>
      <Button v-if="mission && !service?.id" @click="create"
              type="button" class="p-button-secondary">
        {{ me.role.name === constants.ROLES.INDEPENDENT ? 'Créer' : 'Créer le service' }}
      </Button>
      <Button v-if="hasAccess($route.name, true) && mission && service?.id && (!service?.invoice || !service?.invoice?.invoice_number) && service?.invoice?.status !== constants.SERVICE_STATUS.INVOICED" @click="save"
              type="button" class="p-button-secondary">
        {{ me.role.name === constants.ROLES.INDEPENDENT ? 'Enregistrer' : 'Enregistrer le service' }}
      </Button>
    </div>
  </div>
  <Dialog :draggable="false" v-model:visible="showDialogMission" :style="{width: '70%'}" :modal="true" dismissable-mask class="p-fluid">
    <template #header>
      <h3 class="mb-2">Fiche Mission</h3>
    </template>
    <fiche-mission
        ref="mission"
        :key="missionKeyComp"
        :mission="mission"
        :editable="false"
        :independentId="independent.id"
    />
  </Dialog>
</template>

<script>
import {mapState} from "vuex";
import FicheMission from "@/components/FicheMission";
import {disablePComponentsOfCurrentPage, hasAccess} from "@/utils/Misc";

export default {
  name: "CreationService",
  components: {FicheMission},
  emits: ["create", "change"],
  props: {
    service: {
      type: Object,
      required: true
    },
    // unStore disabled missions storing to prevent changing background used data -- see Declaration Facturation
    unStore: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      hasAccess: hasAccess,
      showDialogMission: false,
      missionKeyComp: 0,
      independent: null,
      mission: null,
      moisFacturation: null,
      quantity: null,
      price: null,
      montantHT: null,
      taxes: [],
      reference: null,
      label: null,
      commentaire: '',
      missionOptions: [],
      filteredIndependent: [],
    }
  },
  watch: {
    independent: async function (val) {
      this.mission = null
      if (typeof val === 'object' && !!val?.id) {
        this.missionOptions = await this.$store.dispatch('mission/getMissionsByIndependent', {id: val.id, unStore: this.unStore})
        if (!this.missionOptions || this.missionOptions?.length === 0) return
        if (this.missionOptions?.length === 1) {
          this.mission = this.missionOptions[0]
        } else if (this.service?.mission) {
          this.mission = this.missionOptions.find((miss) => miss.id === this.service?.mission?.id)
        }
      }
    },
    quantity () {
      this.price = this.quantity * this.montantHT
    },
    montantHT () {
      this.price = this.quantity * this.montantHT
    }
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      sideContent: state => state.sidebar.sideContent,
      constants: state => state.constants,
    })
  },
  created () {
    this.updateDatas()
    // this.$store.dispatch('independent/getAllIndependents').then(() => {
    //   console.log('after get indep')
    //   if (this.service && this.service.independent) {
    //     this.independent = this.independents.find((indep) => indep.id === this.service.independent.id)
    //   }
    // })
  },
  mounted () {
    this.$nextTick(() => {
      if (!hasAccess(this.$route.name, true)) disablePComponentsOfCurrentPage(document)
    })
  },
  methods: {
    openFicheMission () {
      this.missionKeyComp++
      this.showDialogMission = true
    },
    create () {
      this.$emit('create', this.getServiceData())
    },
    clearIndependent () {
      this.independent = null
    },
    updateDatas () {
      if (this.service) {
        this.independent = this.service.independent
        this.mission = this.service.mission
        this.moisFacturation = this.service.moisFacturation
        this.quantity = this.service.quantity
        this.price = this.service.price
        this.montantHT = this.service.montantHT
        this.taxes = this.service.taxes
        this.reference = this.service.reference
        this.label = this.service.label
        this.commentaire = this.service.commentaire
      }
    },
    getServiceData () {
      return {
        independent: this.independent,
        mission: this.mission,
        moisFacturation: this.moisFacturation,
        quantity: this.quantity,
        price: this.price,
        montantHT: this.montantHT,
        taxes: this.taxes,
        reference: this.reference,
        label: this.label,
        commentaire: this.commentaire
      }
    },
    save () {
      this.$store.state.misc.loading = true
      let data = this.getServiceData()
      data.id = this.service.id
      this.$store.dispatch('service/saveService', data).then((s) => {
        this.updateDatas()
        this.$store.state.misc.loading = false
        this.$toast.add({severity:'success', summary:'Succès', detail:'Service enregistré', life: 3000});
        this.$emit('change', s)
      }).catch(() => {
        this.$store.state.misc.loading = false
        this.$toast.add({severity:'error', summary:'Erreur', detail:'Une erreur est survenue', life: 3000});
      })
    },
    async searchIndependent (event, force = false) {
      if (force) {
        this.filteredIndependent = await this.$store.dispatch('independent/getAllIndependents')
        return
      }
      if (event.query.length > 1) {
        const data = event.query
        this.filteredIndependent = await this.$store.dispatch('independent/getAllIndependentsFiltered', data)
      }
    },
    selectIndependent (independent) {
      this.independent = independent
    }
  }
}
</script>

<style scoped>

</style>
