<template>
  <div v-if="craDate.loaded && missions && declarationsFacturation && declarationsFacturation.length > 0" class="mt-3">
    <div v-if="missions.length > 0" class="flex justify-content-between pr-7 pb-3">
      <div style="font-size: 1.25rem">Facturation</div>
    </div>
    <div v-if="missions.length > 0" class="grid">
      <div v-for="(mission, indexMission) in missions" :key="indexMission" :class="{'col-4 h-full': getIsBrowserApp, '': !getIsBrowserApp}" class="gap-2">
        <div :class="{'surface-card p-5 shadow-2 border-round h-auto': getIsBrowserApp, 'p-2': !getIsBrowserApp}">
          <div class="flex" :class="{'flex-column xl:flex-row': getIsBrowserApp, 'flex-row': !getIsBrowserApp}">
            <div class="flex mr-2 align-items-center justify-content-center text-white"
                 :class="{'w-8rem h-8rem p-3 border-round-sm': getIsBrowserApp, 'w-5rem h-5rem border-round-sm text-md': !getIsBrowserApp}"
                 :style="`background-color: ${mission?.currentCra?.color};` + (getIsBrowserApp ? `` : ``)">
              <p :class="{'text-sm': getIsBrowserApp, 'text-md': !getIsBrowserApp}" style="word-break: break-word">
                {{ mission.clientContract.name }}
              </p>
            </div>
            <div v-if="mission.jsonCost.negotiatedRateUnit !== constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE" class="flex align-items-center">
              <div class="flex flex-row gap-4">
                <div v-for="(ts, indexTS) in mission?.currentCra?.timesheet" :key="indexTS" class="flex flex-column align-items-center">
                  <div class="flex align-items-center justify-content-center border-circle"
                       :class="{'w-5rem h-5rem': getIsBrowserApp, 'w-3rem h-3rem': !getIsBrowserApp}"
                       :style="'border:' + '2px solid ' + mission?.currentCra?.color">
                    <div class="font-bold"
                         :class="{'text-3xl': getIsBrowserApp, 'text-xl': !getIsBrowserApp}">
                      {{ ts.total }}
                    </div>
                  </div>
                  <div class="font-bold text-center"
                       :class="{'text-md mt-2': getIsBrowserApp, 'text-sm': !getIsBrowserApp}">
                    {{ ts.label }}
                  </div>
                </div>
                <div v-if="editable && getIsBrowserApp">
                  <div class="flex align-items-center justify-content-center transition-duration-100 upload-cra-button"
                       :class="{'w-5rem h-5rem border-circle cursor-pointer': getIsBrowserApp, 'w-3rem h-3rem': !getIsBrowserApp}"
                       :style="`color: ${mission?.currentCra?.color};` + (getIsBrowserApp ? `border: 2px solid ${mission?.currentCra?.color}` : '')"
                       @click="openCRAPreview(mission)">
                    <div class="font-bold text-center text-sm">
                      Téléverser mon CRA
                    </div>
                  </div>
                </div>
                <Button v-if="editable && me.role.name === constants.ROLES.ADMIN" icon="pi pi-plus text-4xl" class="p-button-rounded"
                        :class="{'w-5rem h-5rem': getIsBrowserApp, 'w-3rem h-3rem': !getIsBrowserApp}"
                        @click="manageNegotiatedRateValues(mission)"/>
              </div>
            </div>
            <div v-else>
              <div class="text-2xl text-center font-bold">
                {{ `Mission au forfait (${mission.jsonCost.forfaitFacturationMode === constants.INVOICE_TYPE.MONTHLY ? 'Mensuel' : 'Par échéance'})` }}
              </div>
            </div>
          </div>
          <div v-if="mission.jsonCost.negotiatedRateUnit === constants.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE
            || mission.jsonCost.negotiatedRateUnit === constants.NEGOTIATED_RATE_UNITS.HOURLY_PRICE" class="mt-3">
            <div v-for="(timesheet, indexTS) in mission?.currentCra?.timesheet" :key="indexTS">
              <DataTable v-if="missionPricingByTimesheet[indexMission]" :value="missionPricingByTimesheet[indexMission][indexTS]"
                         :scrollable="true" scroll-direction="vertical" class="p-datatable-sm" selection-mode="single"
                         :rowHover="true" responsiveLayout="scroll">
                <template #header>
                  {{ timesheet.label }}
                </template>
<!--                todo: a faire en fonction de negotiatedRateUnit-->
                <Column field="unitCost" :header="'Taux ' + (mission.jsonCost.negotiatedRateUnit === constants.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE
                 || mission.jsonCost.negotiatedRateUnit === constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE ? 'journalier' : 'horaire')">
                  <template #body="{data}">
                    <span class="font-bold"> {{ `${getEuroFormat(data.unitCost)}` }}</span>
                  </template>
                </Column>
                <Column field="numberOfOccurrences" :header="'Nombre ' + (mission.jsonCost.negotiatedRateUnit === constants.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE
                 || mission.jsonCost.negotiatedRateUnit === constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE ? 'de jours' : 'd\'heures')"/>
                <Column field="totalCostWithoutTaxes" header="Total Hors Taxe">
                  <template #body="{data}">
                    <span> {{ `${getEuroFormat(data.totalCostWithoutTaxes)}` }}</span>
                  </template>
                </Column>
              </DataTable>
            </div>
          </div>
          <div v-else-if="mission.jsonCost.negotiatedRateUnit === constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE
            && missionPricingByTimesheet[indexMission]" class="mt-3">
            <!--  Afficher la mensualité ou les échéances passés pour ce mois  -->
            <div v-if="mission.jsonCost.forfaitFacturationMode === constants.INVOICE_TYPE.MONTHLY">
              <span>
                Le forfait mensuel pour ce mois est de
                <!--  consider that there's only one cost to show in MONTHLY-->
                <span :style="'color:'  + mission.currentCra.color">
                  {{ getEuroFormat(missionPricingByTimesheet[indexMission][0][0].totalCostWithoutTaxes) }}
                </span>
              </span>
            </div>
            <div v-if="mission.jsonCost.forfaitFacturationMode === constants.INVOICE_TYPE.OTHER">
              Les échéances pour cette déclaration de facturation sont les suivantes:
              <ul v-if="missionPricingByTimesheet[indexMission]">
                <li v-for="(dueDate, dueDateIndex) in missionPricingByTimesheet[indexMission][0]" :key="dueDateIndex">
                  <span>
                    {{ `Échéance du ${new Date(dueDate.effectiveDate).toLocaleString('default', { day: '2-digit', month: 'long', year: 'numeric' })} d'un montant de ` }}
                    <span :style="'color:'  + mission.currentCra.color">
                      {{ `${getEuroFormat(dueDate.totalCostWithoutTaxes)}`}}
                    </span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <Textarea v-model="mission.commentaire" class="mt-3 mb-3 w-full border-round-xs" :autoResize="true" rows="3"
                    placeholder="Commentaire de la prestation" :disabled="!(getDF(mission)?.status !== constants.FACTURATION_STATUS.VALIDATED && isSavable)"/>

          <DataTable v-if="getServices(mission).length > 0" :value="getServices(mission)" :scrollable="true" scroll-direction="vertical"
                     class="p-datatable-sm" selection-mode="single" :rowHover="true" responsiveLayout="scroll"
                     @row-click="(event) => serviceClicked(event, mission)">
            <Column field="label" header="Label"></Column>
            <Column field="montantHT" header="Prix Unitaire">
              <template #body="{data}">
                <span> {{ `${getEuroFormatFromEuro(data.montantHT)}` }}</span>
              </template>
            </Column>
            <Column field="quantity" header="Quantité"/>
            <Column field="price" header="Total">
              <template #body="{data}">
                <span> {{ `${getEuroFormatFromEuro(data.price)}` }}</span>
              </template>
            </Column>
            <Column field="status" header="Statut">
              <template #body="{data}">
                <Chip :class="'custom-chip-' + data.status" :label="getOptionLabel(constants.data.serviceOptions, data?.status)?.toUpperCase()"/>
              </template>
            </Column>
            <Column header="Actions">
              <template #body="{data, index}">
                <Button v-if="data.status !== constants.SERVICE_STATUS.INVOICED &&
                     (me.role.name === constants.ROLES.ADMIN || (me.role.name === constants.ROLES.INDEPENDENT && data.user_created === me.id))"
                        icon="pi pi-trash" size="large" text severity="danger" @click.stop="removeService(data, index, mission)"/>
              </template>
            </Column>
          </DataTable>

          <MissionServicesTotals v-if="showMissionTotals(mission)" class="mt-3" :mission="mission" :mission-services="getDF(mission)?.services" :missionTotal="getTotalMission(indexMission)"/>

          <div class="flex flex-row-reverse mt-3">
            <Button v-if="getDF(mission)?.status !== constants.FACTURATION_STATUS.VALIDATED && isSavable" class="mb-2"
                    @click="onClickAddService(mission)">
              {{ me.role.name === constants.ROLES.INDEPENDENT ? 'Ajouter une astreinte' : 'Ajouter un service' }}
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div :class="{'surface-card p-5 shadow-2 border-round': getIsBrowserApp, 'p-2': !getIsBrowserApp}">
      <span class="font-bold">Total:</span>
      <div v-if="missions.length === 1" class="mt-3 font-bold text-4xl">
        <span :style="'color:'  + missions[0]?.currentCra?.color">
          {{ getEuroFormat(getTotalMission(0), false) }} {{ missions[0].jsonProvider.payment.unit }}
        </span>
      </div>
      <div v-else-if="missions.length > 1" class="mt-3 font-bold text-4xl">
        <span v-for="(mission, indexMission) in missions" :key="indexMission">
          <span :style="'color:'  + mission?.currentCra?.color">{{ getEuroFormat(getTotalMission(indexMission)) }}</span>
          <span v-if="indexMission + 1 < missions.length">
            +
          </span>
          <span v-else>
            = <span style="color: grey">{{ getEuroFormat(getTotalAllMission(), false) }} {{ mission.jsonProvider.payment.unit }}</span>
          </span>
        </span>
      </div>
    </div>
    <dialog-file-preview :is-active="filePreviewIsActive" :replaceFileButton="(me.role.name === constants.ROLES.ADMIN || (me.role.name === constants.ROLES.INDEPENDENT && getDF(tmpMission)?.status !== constants.FACTURATION_STATUS.VALIDATED))"
                         :file-id="craFileId" :delete-button="getDF(tmpMission)?.status !== constants.FACTURATION_STATUS.VALIDATED && isSavable"
                         :auto-upload-file="false" closeButton modal
                         firstUploadButtonLabel="Téléverser mon compte rendu d'activité"
                         dialog-title="Joindre votre CRA à votre déclaration de facturation"
                         @closePreview="resetDefaultFilePreview" @closeDialog="resetDefaultFilePreview"
                         @replaceFile="updateFile" @firstFileUpload="firstUpload" @deleteFile="deleteFile"
    />
    <Dialog v-model:visible="openServiceCreation" :draggable="false" dismissable-mask :modal="true" :show-header="false"
            :style="{width: '1000px'}">
      <creation-service :service="service" un-store @change="updateService" @create="create"/>
    </Dialog>
    <dialog-negotiated-rate-values v-if="me.role.name === constants.ROLES.ADMIN && !!tmpMission?.id" :mission="tmpMission"
                                   :is-active="openNegotiatedRateValues"
                                   @closeDialog="closeNegotiatedRateValuesDialog"
                                   @update-negotiated-rate-values="updateNegotiatedRateValues"/>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import { getOptionLabel, hasAccess } from "@/utils/Misc";
import isBrowserAppMixin from "@/mixins/isBrowserAppMixin";
import DialogFilePreview from "@/components/DialogFilePreview.vue";
import CreationService from "@/views/Pages/ADV/CreationService.vue";
import MissionServicesTotals from "@/components/Facturation/MissionServicesTotals.vue";
import DialogNegotiatedRateValues from "@/components/DialogNegotiatedRateValues.vue";
import { setDateWithoutHours } from "@/utils/Date";
import { getEuroFormat, getEuroFormatFromEuro } from "@/utils/Money";

export default {
  name: 'DeclareFactureDetail',
  components: {DialogNegotiatedRateValues, MissionServicesTotals, CreationService, DialogFilePreview},
  mixins: [isBrowserAppMixin],
  props: {
    isSavable: {
      type: Boolean,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      constants: state => state.constants,
      missionPricingByTimesheet: state => state.declarationFacturation.missionPricingByTimesheet
    }),
    ...mapGetters({
      missions: 'mission/getMissions',
      independent: 'independent/getIndependent',
      craDate: 'cra/getDate',
      declarationsFacturation: 'declarationFacturation/getDeclarationsFacturation'
    })
  },
  data () {
    return {
      hasAccess: hasAccess,
      displayAction: false,
      // services: [{label: 'Astreinte', value: 'Astreinte'}], // todo: should be refactor
      keyDialog: 0,
      tmpMission: {},

      localNegotiatedRateValues: [],
      openNegotiatedRateValues: false,

      openServiceCreation: false,
      service: null,

      filePreviewIsActive: false,
      craFileId: null
    }
  },
  watch: {
    'craDate.loaded': {
      handler(value) {
        if (value) {
          // should recall this when the CRA change
          this.$store.dispatch('declarationFacturation/setMissionPricingByTimesheet', [])
          this.initMissionsPricingByTimesheet()
        }
      },
      deep: true
    },
    // todo: this is a bit overpower, should change within a better structure
    'missions': {
      handler() {
        this.$store.dispatch('declarationFacturation/setMissionPricingByTimesheet', [])
        this.initMissionsPricingByTimesheet()
      },
      deep: true
    }
  },
  created () {
    this.$store.dispatch('declarationFacturation/setMissionPricingByTimesheet', [])
    this.initMissionsPricingByTimesheet()
  },
  methods: {
    getEuroFormatFromEuro,
    getEuroFormat,
    getOptionLabel,
    initMissionsPricingByTimesheet () {
      // init loader
      let defineOneFixedPrice = false
      this.missions.forEach((mission, index) => {
        defineOneFixedPrice = false // for multi-mission with different negotiatedRateUnit
        this.$store.dispatch('declarationFacturation/addMissionPricingByTimesheet', [])
        mission?.currentCra?.timesheet?.forEach((timesheet) => {
          if (!defineOneFixedPrice) {
            this.$store.dispatch('declarationFacturation/addSubMissionPricingByTimesheet', {
              missionIndex: index,
              negotiatedRates: this.getNegotiatedRates({timesheet, mission})
            })
          }
          if (mission.jsonCost.negotiatedRateUnit === this.constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE) {
            defineOneFixedPrice = true
          }
        })
      })
    },
    getDF (mission) {
      return this.declarationsFacturation.find(df => df.mission === mission?.id)
    },
    async openCRAPreview(mission) {
      this.tmpMission = JSON.parse(JSON.stringify(mission))
      this.craFileId = JSON.parse(JSON.stringify(this.getDF(this.tmpMission)?.craFile))
      this.currentSelectedFile = this.craFileId
      this.filePreviewIsActive = true
    },
    async firstUpload(payload) {
      // todo: should merge those two requests into one
      const dfId = JSON.parse(JSON.stringify(this.getDF(this.tmpMission)))?.id
      const newCraFile = await this.$store.dispatch('file/uploadFile', payload.form)
      this.resetDefaultFilePreview()
      // todo: should handle those better
      this.$store.dispatch('declarationFacturation/setDeclarationFacturation', {
        id: dfId,
        craFile: newCraFile.id
      })
      this.$store.dispatch('declarationFacturation/updateDeclarationFacturation', {
        id: dfId,
        craFile: newCraFile.id
      })
    },
    async updateFile(payload) {
      await this.firstUpload(payload)
      await this.$store.dispatch('file/deleteFile', {id: this.currentSelectedFile})
    },
    async deleteFile() {
      const dfId = JSON.parse(JSON.stringify(this.getDF(this.tmpMission)))?.id
      await this.$store.dispatch('file/deleteFile', {id: this.currentSelectedFile})
      this.resetDefaultFilePreview()
      // todo: should handle those better
      this.$store.dispatch('declarationFacturation/setDeclarationFacturation', {
        id: dfId,
        craFile: null
      })
      this.$store.dispatch('declarationFacturation/updateDeclarationFacturation', {
        id: dfId,
        craFile: null
      })
    },
    manageNegotiatedRateValues(mission) {
      this.tmpMission = mission
      process.nextTick(() => {
        this.openNegotiatedRateValues = true
      })
    },
    closeNegotiatedRateValuesDialog () {
      this.openNegotiatedRateValues = false
      this.tmpMission = {}
    },
    async updateNegotiatedRateValues(values, forceUpdate = true) {
      this.$store.state.misc.loading = true
      this.tmpMission.jsonCost.negotiatedRateValues = values
      if (forceUpdate) {
        await this.$store.dispatch('mission/saveMission', this.tmpMission)
      }
      this.$store.dispatch('declarationFacturation/setMissionPricingByTimesheet', [])
      this.initMissionsPricingByTimesheet()
      this.$store.state.misc.loading = false
      this.closeNegotiatedRateValuesDialog()
    },
    showMissionTotals (mission) {
      const services = this.getServices(mission)
      if (this.me.role.name === this.constants.ROLES.ADMIN) {
        return services?.length > 0
      } else if (this.me.role.name === this.constants.ROLES.INDEPENDENT) {
        return services?.find((service) => (service.user_created !== this.me.id) || (service.user_updated !== this.me.id && !!service.user_updated) ) !== undefined
      }
    },
    async onClickAddService (mission) {
      // todo: might need to add the source ?
      const date = new Date()
      date.setMonth(this.craDate.month)
      date.setFullYear(this.craDate.year)
      this.service = {
        mission: mission,
        independent: this.independent,
        label: `Astreinte-${new Date(Date.UTC(this.craDate.year, this.craDate.month, 1)).toLocaleDateString('fr-FR', {year: 'numeric', month: 'long'})}`,
        month: this.craDate.month,
        year: this.craDate.year,
        moisFacturation: date,
        montantHT: 0,
        status: 'toInvoice',
        price: 0,
        quantity: 0,
        commentaire: '',
        hide: true
      }
      this.openServiceCreation = true
    },
    saveServiceInStore(missionId, service) {
      let serviceIndex = -1
      this.declarationsFacturation.forEach((declarationFacturation) => {
        if (declarationFacturation.mission === missionId) {
          serviceIndex = declarationFacturation.services.findIndex((invoiceService) => invoiceService.id === service.id)
          if (serviceIndex !== -1 && service?.id) {
            declarationFacturation.services.splice(serviceIndex, 1, service)
          } else if (serviceIndex === -1 && service?.id) {
            declarationFacturation.services.push(service)
          }
        }
      })
      this.$store.dispatch('declarationFacturation/setDeclarationsFacturation', this.declarationsFacturation)
    },
    async removeService(service, index, mission) {
      let currentDeclaration = null
      this.declarationsFacturation.forEach((df, idx) => {
        if (df.mission === mission.id) {
          currentDeclaration = idx
        }
      })
      if (currentDeclaration !== null) {
        try {
          if (service.id) {
            await this.$store.dispatch('service/removeServices', { ids: [service.id], declarationFacturationIndex: currentDeclaration })
            this.$toast.add({severity:'success', summary:'Succès', detail:'Service supprimé', life: 3000});
          }
        } catch (e) {
          this.$toast.add({severity:'error', summary:'Erreur', detail:'Une erreur est survenue', life: 3000});
        }
      }
    },
    getServices(mission) {
      let services = []
      this.declarationsFacturation.forEach((invoiceDeclaration) => {
        if (mission.id === invoiceDeclaration.mission) {
          services.push(...invoiceDeclaration.services)
        }
      })
      return services
    },
    resetDefaultFilePreview() {
      this.filePreviewIsActive = false
      this.tmpMission = null
      this.craFileId = null
    },
    closeServiceDialog () {
      this.openServiceCreation = false
    },
    async create (val) {
      val.declaration_facturation = this.getDF(val.mission)?.id
      val.mission = val.mission.id
      await this.saveService(val)
    },
    async updateService (val) {
      val.mission = val.mission.id
      await this.saveService(val)
    },
    serviceClicked (event, mission) {
      if (this.me.role.name === this.constants.ROLES.ADMIN ||
          this.me.id === event.data.user_created) {
        this.service = JSON.parse(JSON.stringify(event.data))
        this.service.mission = mission
        this.service.independent = this.independent
        this.service.moisFacturation = this.service.moisFacturation ? new Date(this.service.moisFacturation) : null
        this.openServiceCreation = true
      }
    },
    async saveService (val) {
      process.nextTick(async () => {
        let savedService = val
        try {
          if (!savedService?.id) {
            this.$store.state.misc.loading = true
            savedService = await this.$store.dispatch('service/saveService', val)
          }
          await this.saveServiceInStore(savedService.mission, savedService)
          this.closeServiceDialog()
          this.$store.state.misc.loading = false
          this.$toast.add({severity:'success', summary:'Succès', detail:'Service créé', life: 3000});
        } catch (e) {
          console.log(e)
          this.$store.state.misc.loading = false
          this.$toast.add({severity:'error', summary:'Erreur', detail:'Une erreur est survenue', life: 3000});
        }
      })
    },
    computeTotals () {
      this.missions.forEach((m) => {
        if (m.jsonCost.negotiatedRateUnit === this.constants.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE) {
          m.currentCra.timesheet.forEach((ts) => {
            ts.total = 0
            ts.value.forEach((v) => {
              switch (v) {
                case 1:
                  ts.total += 1
                  break
                case 2:
                  ts.total += 0.5
                  break
                case 3:
                  ts.total += 0.5
                  break
              }
            })
          })
        } else if (m.jsonCost.negotiatedRateUnit === this.constants.NEGOTIATED_RATE_UNITS.HOURLY_PRICE) {
          m.currentCra.timesheet.forEach((ts) => {
            ts.total = 0
            ts.value.forEach((v, index) => {
              if (!v) {
                ts.value[index] = null
              } else if (v < 1 || v > 12) {
                ts.value[index] = null
              } else {
                ts.total += parseInt(v)
              }
            })
          })
        }
      })
    },
    getTotalMission(missionIndex) {
      if (!this.missionPricingByTimesheet[missionIndex]) return 0
      let total = 0
      this.missionPricingByTimesheet[missionIndex].forEach((timesheetTypes) => {
        timesheetTypes.forEach((timesheet) =>
            total += timesheet.totalCostWithoutTaxes
        )
      })
      return total
      // return (total / 100).toFixed(2)
    },
    getTotalAllMission() {
      let totalAll = 0
      this.missions.forEach((mission, index) => {
        totalAll += this.getTotalMission(index)
      })
      return totalAll
      // return (totalAll / 100).toFixed(2)
    },
    getFixedPriceNegotiatedRates(payload) {
      const currentDate = new Date()
      let negotiatedRates = payload.mission.jsonCost.negotiatedRateValues
          .sort((a, b) => a.effectiveDate > b.effectiveDate ? -1 : a.effectiveDate < b.effectiveDate ? 1 : 0)
          .filter(negotiatedRate => {
            if (negotiatedRate.unit !== this.constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE) return false
            const effectiveDate = new Date(negotiatedRate.effectiveDate)
            if (payload.mission.jsonCost.forfaitFacturationMode === this.constants.INVOICE_TYPE.OTHER) {
              return currentDate.getTime() >= effectiveDate.getTime()
            } else if (payload.mission.jsonCost.forfaitFacturationMode === this.constants.INVOICE_TYPE.MONTHLY) {
              return effectiveDate.getFullYear() < this.craDate.year || (effectiveDate.getFullYear() === this.craDate.year && effectiveDate.getMonth() <= this.craDate.month)
            }
          })

      if (payload.mission.jsonCost.forfaitFacturationMode === this.constants.INVOICE_TYPE.MONTHLY) {
        negotiatedRates = [negotiatedRates[0]]
      }

      return negotiatedRates
          .map(negotiatedRate => {
            return {
              ...negotiatedRate,
              unitCost: Math.round(negotiatedRate.costOnSite * 100), // always one price, even with remoteWork
              numberOfOccurrences: 1,
              totalCostWithoutTaxes: Math.round(negotiatedRate.costOnSite * 100),
            }
          })
    },
    findNegotiatedRateIndexForDate (negotiatedRates, date) {
      let lastValidNegotiatedRateIndex = -1
      if (!negotiatedRates || negotiatedRates?.length === 0) return -1
      for (let i = 0; i < negotiatedRates.length; i++) {
        if (date >= negotiatedRates[i].effectiveDate && date >= negotiatedRates[i + 1]?.effectiveDate) {
          lastValidNegotiatedRateIndex = i
        } else if (date >= negotiatedRates[i].effectiveDate && (date <= negotiatedRates[i + 1]?.effectiveDate || !negotiatedRates[i + 1]?.effectiveDate)) {
          return i
        } else {
          return lastValidNegotiatedRateIndex
        }
      }
      return -1
    },
    getNegotiatedRates (payload) {
      if (payload.mission.jsonCost.negotiatedRateUnit === this.constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE)
        return this.getFixedPriceNegotiatedRates(payload)

      let negotiatedRates = JSON.parse(JSON.stringify(payload.mission.jsonCost.negotiatedRateValues))
          .filter(negotiatedRate => {
            const effectiveDate = new Date(negotiatedRate.effectiveDate)
            return negotiatedRate.unit === payload.mission.jsonCost.negotiatedRateUnit
                && (effectiveDate.getFullYear() <= this.craDate.year || (effectiveDate.getFullYear() <= this.craDate.year && effectiveDate.getMonth() <= this.craDate.month))
          })
          .sort((a, b) => a.effectiveDate > b.effectiveDate ? 1 : a.effectiveDate < b.effectiveDate ? -1 : 0)

      // format the content in any case
      negotiatedRates = negotiatedRates
          .map((negotiatedRate) => {
            return {
              ...negotiatedRate,
              // todo: change for a better the check instead of the label check
              unitCost: Math.round((payload.timesheet.label.toLowerCase() === 'sur site' ? negotiatedRate.costOnSite : negotiatedRate.costOnRemote) * 100),
              numberOfOccurrences: 0,
              totalCostWithoutTaxes: 0,
              label: payload.timesheet.label
            }
          })

      let timesheetDayDate

      let timesheetValuesIndex = 0
      let negotiatedRateIndex = 0

      const negotiatedRatesWithoutHours = negotiatedRates.map((negotiatedRate) => {
        return {
          ...negotiatedRate,
          effectiveDate: setDateWithoutHours(negotiatedRate.effectiveDate)
        }
      })
      while (timesheetValuesIndex < this.craDate.days.length) {
        timesheetDayDate = setDateWithoutHours(this.craDate.days[timesheetValuesIndex])

        if (payload.timesheet.value[timesheetValuesIndex]) {
          negotiatedRateIndex = this.findNegotiatedRateIndexForDate(negotiatedRatesWithoutHours, timesheetDayDate)
          if (negotiatedRateIndex !== -1) {
            negotiatedRates[negotiatedRateIndex].numberOfOccurrences += payload.mission.jsonCost.negotiatedRateUnit === this.constants.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE ?
                (((payload.timesheet.value[timesheetValuesIndex] === 2 || payload.timesheet.value[timesheetValuesIndex] === 3) ? 0.5 : 1))
                : (payload.timesheet.value[timesheetValuesIndex])
            // todo: TJ => basic cost / (1 || 2 || 3) // Hours => basic cost * value // FixedPrice => no cra, see what to do
            if (payload.mission.jsonCost.negotiatedRateUnit === this.constants.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE) {
              negotiatedRates[negotiatedRateIndex].totalCostWithoutTaxes += negotiatedRates[negotiatedRateIndex].unitCost /
                  ((payload.timesheet.value[timesheetValuesIndex] === 2 || payload.timesheet.value[timesheetValuesIndex] === 3) ? 2 : 1)
            } else if (payload.mission.jsonCost.negotiatedRateUnit === this.constants.NEGOTIATED_RATE_UNITS.HOURLY_PRICE) {
              negotiatedRates[negotiatedRateIndex].totalCostWithoutTaxes += (negotiatedRates[negotiatedRateIndex].unitCost * payload.timesheet.value[timesheetValuesIndex])
            }
          }
          // do nothing if there's no specific negotiatedRate for the specified date
        }
        ++timesheetValuesIndex
      }
      negotiatedRates = negotiatedRates
          .filter(negotiatedRate => (!!negotiatedRate.totalCostWithoutTaxes || negotiatedRate.totalCostWithoutTaxes > 0) && (!!negotiatedRate.numberOfOccurrences || negotiatedRate.numberOfOccurrences > 0))
      return negotiatedRates
    }
  }
}
</script>

<style scoped>
.upload-cra-button:hover {
  background-color: var(--primary-color) !important;
  color: white !important;
}
.custom-chip-toInvoice {
  background-color: #feffcc;
  color: #dbdf0c;
}
.custom-chip-invoicing {
  background-color: #cce4ff;
  color: #2E6BAB;
}
.custom-chip-invoiced {
  background-color: #ebffcc;
  color: #97C353;
}
</style>
