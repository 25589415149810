<template>
  <div>
    <span class="font-bold">Différence du paiement demandé par raport au facturé:</span>
    <div class="mt-3 font-bold align-items-center text-3xl">
            <span :style="'color:'  + mission?.currentCra?.color">
              {{ getEuroFormat(missionTotal, false) }}
            </span>
      <span> - </span>
      <span> {{ getEuroFormat(getTotalPriceServicesFormatted, false) }} </span>
      <span> = </span>
      <span class="mr-3" :class="{ 'text-primary': getCentsDifference === 0, 'text-orange-400': getCentsDifference < 0 || getCentsDifference > 0}">
        {{ getEuroFormat(getDifferenceFormatted, false) }} {{ mission.jsonProvider.payment.unit }}
      </span>
      <span v-if="getCentsDifference === 0">
        <i class="pi pi-check-circle text-2xl text-primary"/>
      </span>
      <span v-if="getCentsDifference < 0" class="text-orange-400">
        <i class="pi pi-exclamation-circle text-2xl"/>
        <br/>
        <span class="text-xl">Le total a facturé (ou facturé) est supérieur à la paie demandée</span>
      </span>
      <span v-if="getCentsDifference > 0" class="text-orange-400">
        <i class="pi pi-exclamation-circle text-2xl"/>
        <br/>
        <span class="text-xl">Le total a facturé (ou facturé) est inférieur à la paie demandée</span>
      </span>
    </div>
  </div>
</template>

<script>
import {getEuroFormat} from "@/utils/Money";

export default {
  name: "MissionServicesTotals",
  methods: {getEuroFormat},
  props: {
    mission: {
      type: Object,
      required: true
    },
    missionServices: {
      type: Array,
      required: true
    },
    missionTotal: {
      type: Number,
      required: true
    }
  },
  data () {
    return {

    }
  },
  computed: {
    getTotalCentsPriceServices () {
      if (!this.missionServices || this.missionServices.length === 0) return 0
      let total = 0
      total += this.missionServices.reduce((accumulator, currentValue) =>
          accumulator + Math.round(currentValue.price * 100), total
      )
      // (Math.round(total / 100)).toFixed(2)
      return total
    },
    getTotalPriceServicesFormatted () {
      return this.getTotalCentsPriceServices
    },
    getCentsDifference () {
      return this.missionTotal - this.getTotalCentsPriceServices
    },
    getDifferenceFormatted () {
      return this.getCentsDifference
    }
  }
}
</script>

<style scoped>

</style>
